import { camelCaseKeys } from 'dealer/lib/utils';
import React from 'react';
import { Navigate, redirectDocument } from 'react-router-dom';

const DEFAULT_DEALER_USER = camelCaseKeys(window['gon']?.dealer_user || null);

const DealerUserContext = React.createContext({
  user: DEFAULT_DEALER_USER,
  setUser: (_) => {},
});

// export function dealerUserRedirect(dealerUser) {
//   const dealer = dealerUser?.dealer;
//   const subdomain = window['gon']?.subdomain;
//   if (dealer && dealer.slug === subdomain) {
//     return ;
//   }
//   const domain = window['gon']?.domain; // window.location.host
//   const port = window.location.host.split(':').slice(1).join();
//   const suffix = port ? `:${port}` : '';
//   const url = `${window.location.protocol}//${dealer.slug}.${domain}${suffix}/login`;
//   return redirectDocument(url);
// }

export function DealerUserProvider({ children }) {
  const [user, setUser] = React.useState(DEFAULT_DEALER_USER);

  return (
    <DealerUserContext.Provider value={{ user, setUser }}>
      {children}
    </DealerUserContext.Provider>
  );
}

export function useDealerUserContext() {
  return React.useContext(DealerUserContext);
}
export function useDealerUser() {
  const { user } = useDealerUserContext();
  return user;
}

export function AuthenticatedRoute({ children }) {
  const user = useDealerUser();
  if (!user) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}
