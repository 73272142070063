import AuthLayout from 'dealer/layouts/AuthLayout';
import React from 'react';
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.log('Error:', error);
  return (
    <AuthLayout>
      <div className="text-center">
        {isRouteErrorResponse(error) ? (
          <>
            <h6 className="text-uppercase text-body-secondary mb-4">
              {error.status} Error
            </h6>
            <h1 className="display-4 mb-3">{error.statusText}</h1>
            {error.data?.message && (
              <p className="text-body-secondary mb-4">{error.data.message}</p>
            )}
          </>
        ) : (
          <>
            <h6 className="text-uppercase text-body-secondary mb-4">Uh Oh!</h6>
            <h1 className="display-4 mb-3">An unknown error has occurred</h1>
            <p className="text-body-secondary mb-4">Please try again later</p>
          </>
        )}
        <Link to="/" className="btn btn-lg btn-primary">
          Return to your dashboard
        </Link>
      </div>
    </AuthLayout>
  );
}
