import { FocusableItem } from '@szhsin/react-menu';
import React from 'react';
import { Link } from 'react-router-dom';

export default function MenuLink({ to, children }) {
  return (
    <FocusableItem>
      {({ ref, closeMenu }) => (
        <Link
          ref={ref}
          to={to}
          onClick={({ detail }) =>
            closeMenu(detail === 0 ? 'Enter' : undefined)
          }
        >
          {children}
        </Link>
      )}
    </FocusableItem>
  );
}
