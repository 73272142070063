import React from 'react';
import { Search } from 'react-feather';
import { Link, Outlet } from 'react-router-dom';
import LogoPlaceholder from './placeholder.jpg';
import AvatarPlaceholder from './avatar.png';
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
  MenuDivider,
  FocusableItem,
} from '@szhsin/react-menu';
import MenuLink from 'dealer/components/MenuLink';
import { AuthenticatedRoute } from 'dealer/contexts/DealerUserContext';

function NavbarSearch() {
  return (
    <form className="form-inline me-4 d-none d-lg-flex">
      <div className="input-group input-group-rounded input-group-merge input-group-reverse">
        <input
          type="search"
          className="form-control dropdown-toggle list-search"
          placeholder="Search"
        />
        <div className="input-group-text">
          <Search />
        </div>
        {/* TODO: Drop down menu for search bar results... */}
      </div>
    </form>
  );
}

function NavbarUser() {
  return (
    <div className="navbar-user">
      <Menu
        menuButton={
          <MenuButton className="avatar avatar-sm">
            <img
              src={AvatarPlaceholder}
              alt="Avatar"
              className="avatar-img rounded"
            />
          </MenuButton>
        }
      >
        <MenuLink to="/profile/edit">Profile</MenuLink>
        <MenuLink to="/organization/edit">Organization</MenuLink>
        <MenuDivider />
        <MenuLink to="/logout">Logout</MenuLink>
      </Menu>
    </div>
  );
}

function NavbarMenu() {
  return (
    <div
      id="navbar"
      className="collapse navbar-collapse me-lg-auto order-lg-first"
    >
      {/* TODO: mobile search form */}
      <ul className="navbar-nav me-lg-auto">
        <li className="nav-item">
          <Link to="/" className="nav-link">
            Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/accounts" className="nav-link">
            Accounts
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default function AppLayout() {
  return (
    <AuthenticatedRoute>
      <div className="mvh-100">
        <nav id="topnav" className="navbar navbar-expand-lg">
          <div className="container">
            <button className="navbar-toggler me-auto" type="button">
              <span className="navbar-toggler-icon"></span>
            </button>

            <Link className="navbar-brand me-auto" to="/">
              <img
                src={LogoPlaceholder}
                alt="Upload Your Logo"
                className="navbar-brand-img"
              />
            </Link>

            {/* <NavbarSearch /> */}
            <NavbarUser />
            <NavbarMenu />
          </div>
        </nav>

        <div className="main-content">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
    </AuthenticatedRoute>
  );
}
