import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
// import 'shared/styles/portal/index.scss';
// import "@szhsin/react-menu/dist/index.css";
// import 'shared/styles/dashkit/theme.scss';
import './styles.scss';
import App from './App';

ReactModal.setAppElement('#app');

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
