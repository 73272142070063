import { ApolloProvider } from '@apollo/client';
import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import ErrorPage from './pages/ErrorPage';
import LoadingPage from './pages/LoadingPage';
import apollo from './lib/apollo';
import { DealerUserProvider } from './contexts/DealerUserContext';

// AUTH PAGES
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SignUpPage = lazy(() => import('./pages/SignUpPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const SetupPage = lazy(() => import('./pages/SetupPage'));
const LogoutPage = lazy(() => import('./pages/LogoutPage'));
// const LogoutPage = lazy(() => import('./pages/LogoutPage'));

// BACKOFFICE PAGES
const ProfileLayout = lazy(() => import('./pages/profile/ProfileLayout'));
const EditProfilePage = lazy(() => import('./pages/profile/EditProfilePage'));
const SecurityProfilePage = lazy(
  () => import('./pages/profile/SecurityProfilePage')
);
const LicensesProfilePage = lazy(
  () => import('./pages/profile/LicensesProfilePage')
);
const OrganizationLayout = lazy(
  () => import('./pages/organization/OrganizationLayout')
);
const EditOrganizationPage = lazy(
  () => import('./pages/organization/EditOrganizationPage')
);
const MembersOrganizationPage = lazy(
  () => import('./pages/organization/MembersOrganizationPage')
);
const TransactionsOrganizationPage = lazy(
  () => import('./pages/organization/TransactionsOrganizationPage')
);

// OPPORTUNTIY PAGES
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const AccountsPage = lazy(() => import('./pages/accounts/AccountsPage'));
const NewAccountPage = lazy(() => import('./pages/accounts/NewAccountPage'));
const AccountPage = lazy(() => import('./pages/accounts/AccountPage'));
const AccountDesignsPage = lazy(
  () => import('./pages/accounts/AccountDesignsPage')
);
const AccountQuotesPage = lazy(
  () => import('./pages/accounts/AccountQuotesPage')
);
const AccountTodosPage = lazy(
  () => import('./pages/accounts/AccountTodosPage')
);

const children = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/logout',
    element: <LogoutPage />,
  },
  {
    path: '/signup',
    element: <SignUpPage />,
  },
  {
    path: '/forgot',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/setup',
    element: <SetupPage />,
  },
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <DashboardPage />,
      },
      {
        path: '/accounts',
        element: <AccountsPage />,
      },
      {
        path: '/accounts/new',
        element: <NewAccountPage />,
      },
      {
        path: '/accounts/:id',
        element: <AccountPage />,
      },
      {
        path: '/accounts/:id/designs',
        element: <AccountDesignsPage />,
      },
      {
        path: '/accounts/:id/quotes',
        element: <AccountQuotesPage />,
      },
      {
        path: '/accounts/:id/todos',
        element: <AccountTodosPage />,
      },
      {
        element: <OrganizationLayout />,
        children: [
          {
            path: '/organization/edit',
            element: <EditOrganizationPage />,
          },
          {
            path: '/organization/members',
            element: <MembersOrganizationPage />,
          },
          {
            path: '/organization/transactions',
            element: <TransactionsOrganizationPage />,
          },
          {
            path: '/organization',
            element: <Navigate to="/organization/edit" replace />,
          },
        ],
      },
      {
        element: <ProfileLayout />,
        children: [
          {
            path: '/profile/edit',
            element: <EditProfilePage />,
          },
          {
            path: '/profile/security',
            element: <SecurityProfilePage />,
          },
          {
            path: '/profile/licenses',
            element: <LicensesProfilePage />,
          },
          {
            path: '/profile',
            element: <Navigate to="/profile/edit" replace />,
          },
        ],
      },
    ],
  },
  {
    path: '/*',
    element: <NotFoundPage />,
    handle: {
      crumb: (_) => `Not Found`,
    },
  },
];

function Root() {
  return (
    <ApolloProvider client={apollo}>
      <DealerUserProvider>
        <Suspense fallback={<LoadingPage />}>
          <Outlet />
        </Suspense>
      </DealerUserProvider>
    </ApolloProvider>
  );
}

export const routes = [
  {
    path: '/',
    element: <Root />,
    children,
    errorElement: <ErrorPage />,
  },
];
