import React from 'react';

export default function AuthLayout({ children }) {
  return (
    <div className="mvh-100 d-flex align-items-center bg-auth border-top border-top-2 border-primary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-5 col-xl-4 my-5">{children}</div>
        </div>
      </div>
    </div>
  );
}
